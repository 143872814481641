import { rem } from 'polished';

import { styled } from '~/stitches.config';
import { ReactComponent as DropdownIcon } from '~/images/arrow.svg';

export const Container = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  variants: {
    small: {
      true: {
        width: rem(160),
        padding: `${rem(10)} 0`,
        border: '1px solid #C1C3C9',
        borderRadius: rem(8),

        fontSize: rem(14),
        ['&>div']: {
          width: '100%',

          color: '$backgroundIcon',
        },
      },
    },
  },
});

export const Label = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingLeft: rem(16),
  color: '$textPrimary',
  fontSize: rem(14),
  textOverflow: 'ellipsis',
});

export const Arrow = styled(DropdownIcon as unknown as 'svg', {
  position: 'absolute',
  top: '50%',
  right: rem(20),
  width: rem(8),
  overflow: 'visible',
  color: '$backgroundIcon',
  transform: 'translateY(-50%)',
  transition: 'transform 0.2s ease',
  pointerEvents: 'none',
  variants: {
    open: {
      true: {
        transform: 'translateY(-50%) rotate(180deg)',
      },
    },
  },
});

export const ListContainer = styled('div', {
  position: 'absolute',
  top: 'calc(100% + 1px)',
  left: 0,
  zIndex: 1,
  minWidth: '100%',
  padding: rem(8),
  backgroundColor: '$white',
  boxShadow: '2px 2px 8px rgba(89, 94, 137, 0.15)',
  opacity: 0,
  transition: '$opacity',
  pointerEvents: 'none',
  cursor: 'default',
  variants: {
    open: {
      true: {
        opacity: 1,

        pointerEvents: 'auto',
      },
    },
  },
});

export const List = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: 0,
  padding: 0,
  overflowX: 'auto',
  overflowY: 'auto',
  listStyle: 'none',
  webkitOverflowScrolling: 'touch',
});

export const ListItem = styled('li', {
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  height: rem(38),
  padding: rem(8),
  fontSize: rem(14),
  wordBreak: 'keep-all',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease',
  ['&:hover']: {
    borderRadius: rem(8),

    backgroundColor: '$backgroundOverlay',
  },
  ['&:not(:last-of-type)']: {
    marginBottom: rem(4),
  },
  ['@lg']: {
    paddingY: 4,
    paddingX: 8,
  },
  ['&.th']: {
    fontFamily: '"Noto Sans Thai", sans-serif',
  },
  variants: {
    selected: {
      true: {
        fontWeight: '$bold',
      },
    },
  },
});
