import { styled, globalCss } from '~/stitches.config';

export const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    WebkitTapHighlightColor: 'transparent',
  },
  'html': {
    height: '100vh',
  },
  'body': {
    width: '100%',
    margin: 0,
    height: '100%',
    overflowY: 'scroll',

    fontFamily: '"Inter", sans-serif',
    fontWeight: '$regular',
    textRendering: 'optimizeLegibility',
    wordBreak: 'break-word',
    color: '$black',
    background: '$white',

    WebkitFontSmoothing: 'antialiased',
    WebkitTextSizeAdjust: '100%',
  },
  'body:lang(ja)': {
    fontFamily: '"Noto Sans JP", sans-serif',
  },
  'body:lang(ko)': {
    wordBreak: 'keep-all',
    fontFamily: '"Noto Sans KR", sans-serif',
  },
  'body:lang(th)': {
    fontFamily: '"Noto Sans Thai", sans-serif',
  },
  'body:lang(zh-Hans)': {
    fontFamily: '"Noto Sans SC", sans-serif',
  },
  'body:lang(zh-Hant)': {
    fontFamily: '"Noto Sans TC", sans-serif',
  },
  'ul, ol': {
    listStyle: 'none',
  },
  'a': {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  '.js-focus-visible :focus:not(.focus-visible)': {
    outline: 'none',
  },
  '[data-js-focus-visible] :focus:not([data-focus-visible-added])': {
    outline: 'none',
  },
  '.focus-visible': {
    outline: '$orange auto 1px',
  },
  '::selection': {
    backgroundColor: '$orange',
    color: '$white',
  },
});

export const ContentContainer = styled('main', {
  display: 'flex',
  column: true,
  maxWidth: '$maxContents',
  margin: '0 auto',
  paddingTop: '$mobileHeaderHeight',
  paddingX: 16,
  ['@md']: {
    paddingTop: '$desktopHeaderHeight',
    paddingX: 24,
  },
  ['@lg']: {
    paddingX: 0,
  },
});
