import { rem } from 'polished';

import { styled } from '~/stitches.config';

export const ContentsTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: rem(32),
  marginTop: rem(40),
  marginBottom: rem(30),
  wordBreak: 'keep-all',
  overflowWrap: 'break-word',
  ['@md']: {
    marginTop: rem(60),
    marginBottom: rem(40),
  },
});

export const ContentsSelectContainer = styled('div', {
  marginBottom: rem(20),
  ['@md']: {
    marginBottom: rem(30),
  },
});

export const ContentsHider = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: rem(80),
  background: 'linear-gradient(360deg, $white 30.83%, rgba(255, 255, 255, 0) 100%)',
  pointerEvents: 'none',
});

export const ContentsContainer = styled('div', {
  paddingBottom: rem(80),
  fontSize: rem(14),
  lineHeight: 1.6,
  overflowWrap: 'break-word',
  ['& *']: {
    marginY: '1em',
  },
  ['& ul, ol']: {
    paddingLeft: rem(40),
  },
  ['& ul']: {
    listStyle: 'disc',
  },
  ['& ol']: {
    listStyle: 'decimal',
  },
  ['& li']: {
    marginY: '1em',
  },
  ['& a']: {
    textDecoration: 'underline',
  },
  ['& table']: {
    minWidth: '100%',
    maxWidth: '100%',
    border: 'none',
    borderCollapse: 'collapse',

    textAlign: 'center',

    wordBreak: 'break-all',

    emptyCells: 'show',
  },
  ['& table th']: {
    fontWeight: '$bold',

    background: '#ececec',
  },
  ['& table td, th']: {
    minWidth: rem(5),
    padding: 1,
    border: '1px solid #ddd',

    fontSize: rem(14),
  },
});
