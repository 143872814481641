import * as React from 'react';
import { graphql, navigate } from 'gatsby';

import Layout from '~/layouts';
import CategoryNavigation from '~/components/CategoryNavigation';
import SEO from '~/components/SEO';
import Contents from '~/components/Contents';

import type { PageProps } from 'gatsby';

type LocalizedIndexPageProps = PageProps<
GatsbyTypes.LocalizedIndexPageQuery,
{ language: string, id: string, categoryId: number }
>;

type ENV = 'preview' | 'production';
const DEPLOY_ENV = process.env.GATSBY_DEPLOY_ENV ?? 'preview' as ENV;

const LocalizedIndexPage: React.FC<LocalizedIndexPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  const strapiPolicyMainNodes = data.allStrapiPolicyMain.nodes
    .filter((node) => node.categories && node.categories.length !== 0)
    .filter((node) => node.environment === DEPLOY_ENV);

  const allLanguages = strapiPolicyMainNodes
    .map((node) => node.language?.replace('_', '-'))
    .filter(Boolean) as string[];
  const currentLanguage = pageContext.language.replace('_', '-');

  type Categories = React.ComponentProps<typeof CategoryNavigation>['categories'];
  type Contents = React.ComponentProps<typeof Contents>['contents'];
  const categories = (data.strapiPolicyMain?.categories as Categories) || [];
  const currentCategoryId = pageContext.categoryId;
  const currentCategoryName = categories
    .find((category) => category.id === currentCategoryId)
    ?.name || '';
  const currentCategoryUrlSlug = categories
    .find((category) => category.id === currentCategoryId)
    ?.urlSlug || '';
  const contents = (data.strapiPolicyCategory?.contents as Contents) || [];

  const getDefaultContentVersion = () => {
    let defaultContentVersion = data.strapiPolicyCategory?.defaultContent?.version;
    if (!defaultContentVersion) {
      defaultContentVersion = contents[0] ? contents[contents.length - 1].version : '';
    }
    return defaultContentVersion;
  };

  React.useEffect(() => {
    const normalizedPathname = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;
    if (
      normalizedPathname === `/${currentLanguage}/`
      || normalizedPathname === `/${currentLanguage}/${currentCategoryUrlSlug}/default/`
    ) {
      void navigate(`/${currentLanguage}/${currentCategoryUrlSlug}/`, { replace: true });
    }
  }, [location, currentLanguage, currentCategoryUrlSlug]);

  if (!categories) {
    throw new Error(
      'StrapiPolicyMain 카테고리가 없습니다.'
      + `언어: ${currentLanguage}, 카테고리 ID: ${currentCategoryId}`,
    );
  }

  return (
    <Layout currentLanguage={currentLanguage} languages={allLanguages} >
      <SEO
        language={currentLanguage}
        currentCategory={currentCategoryName}
        pathname={location.pathname}
      />
      <CategoryNavigation
        currentLanguage={currentLanguage}
        categories={categories}
        currentCategoryId={currentCategoryId}
      />
      <Contents
        currentCategory={currentCategoryName}
        contents={contents}
        defaultContentVersion={getDefaultContentVersion()}
      />
    </Layout >
  );
};

export default LocalizedIndexPage;

export const query = graphql`
  query LocalizedIndexPage(
    $id: String!
    $categoryId: Int!
  ) {
    allStrapiPolicyMain {
      nodes {
        id
        language
        environment
        categories {
          id
        }
      }
    }
    strapiPolicyMain(id: { eq: $id }) {
      language
      categories {
        id
        name
        urlSlug
      }
    }
    strapiPolicyCategory(strapiId: { eq: $categoryId }) {
      contents {
        id
        title
        content
        version
      }
      defaultContent {
        version
      }
    }
  }
`;
