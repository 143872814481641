import * as React from 'react';

const getTargetX = (
  containerElement: HTMLElement,
  itemElement: HTMLLIElement,
) => {
  const { offsetWidth: containerWidth, scrollLeft } = containerElement;
  const containerLeft = containerElement.getBoundingClientRect().left;

  const { offsetWidth: itemWidth } = itemElement;
  const itemLeft = itemElement.getBoundingClientRect().left;

  const refineLeft = itemLeft - containerLeft;
  return scrollLeft + refineLeft - (containerWidth / 2) + (itemWidth / 2);
};

export const scrollToCenter = (
  containerRef: React.RefObject<HTMLElement>,
  itemRef: React.RefObject<HTMLLIElement>,
) => {
  const containerElement = containerRef.current;
  const itemElement = itemRef.current;

  if (containerElement && itemElement) {
    const targetX = getTargetX(containerElement, itemElement);
    containerElement.scroll({ left: targetX, top: 0, behavior: 'smooth' });
  }
};
