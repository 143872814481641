import { Link as _Link } from 'gatsby';
import { rem } from 'polished';

import { styled } from '~/stitches.config';

export const Container = styled('li', {
  display: 'inline-block',
  paddingY: 9,
  ['&:not(:first-of-type)::before']: {
    display: 'inline-block',
    width: rem(2),
    height: rem(10),

    backgroundColor: '$border',

    content: '""',
    marginX: 14,
  },
  ['@lg']: {
    paddingY: 11,
    ['&:not(:first-of-type)::before']: {
      marginX: 16,
    },
  },
});

export const Link = styled(_Link, {
  position: 'relative',
  lineHeight: '160%',
  fontSize: rem(14),
  letterSpacing: '-0.02em',
  color: '$text',
  cursor: 'pointer',
  ['&:after']: {
    position: 'absolute',
    bottom: rem(-9),
    left: 0,

    height: rem(2),

    backgroundColor: '$orange',

    content: '""',
  },
  ['@lg']: {
    fontSize: rem(16),
    ['&:after']: {
      bottom: rem(-11),
    },
  },
  variants: {
    selected: {
      true: {
        color: '$textPrimary',
        fontWeight: '$bold',
        letterSpacing: '-0.035em',

        cursor: 'default',
        ['&:after']: {
          width: '100%',
        },
      },
    },
  },
});
