import { rem } from 'polished';

import { styled } from '~/stitches.config';

export const Container = styled('header', {
  display: 'none',
  ['@md']: {
    position: 'fixed',
    top: 0,
    left: 0,

    display: 'block',
    width: '100vw',
    height: '$desktopHeaderHeight',

    backgroundColor: '$white',
    boxShadow: '$borderBottomGray',
  },
});

export const Contents = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '$maxContents',
  height: '100%',
  margin: '0 auto',
  paddingLeft: rem(24),
  ['@md']: {
    paddingLeft: 0,
  },
});

export const CiContainer = styled('div', {
  paddingX: 16,
  ['@lg']: {
    paddingX: 0,
  },
});

export const SelectContainer = styled('div', {
  width: rem(164),
  height: '100%',
  boxShadow: '$borderGray',
  ['@md']: {
    width: rem(180),
  },
});
