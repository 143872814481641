import * as React from 'react';

import { scrollToCenter } from '~/utils/scroll';

import { Container, Link } from './styles';

type Props = {
  selected: boolean,
  to: string,
  name: string,
  containerRef: React.RefObject<HTMLUListElement>,
};

const NavigationListItem = ({
  selected,
  to,
  name,
  containerRef,
}: Props) => {
  const itemRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (itemRef !== null && selected) {
      scrollToCenter(containerRef, itemRef);
    }
  }, [selected, containerRef]);

  return (
    <Container ref={itemRef}>
      <Link selected={selected} to={to} className='category-link'>
        {name}
      </Link>
    </Container>
  );
};

export default NavigationListItem;
