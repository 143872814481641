import * as React from 'react';
import { pipe } from 'fp-ts/lib/function';
import { map } from 'fp-ts/lib/Array';

import useOnClickOutside from '~/hooks/useOnClickOutside';

import {
  Arrow, Container, Label, List, ListContainer, ListItem,
} from './styles';

type Item = { value: string, label: string };
type OnChangeHandler = (v: Item) => void;
type SelectProps = {
  placeholder?: string,
  items: Item[],
  value: Item,
  onChange: OnChangeHandler,
  onClose?: () => void,
  className?: string,
  type?: 'medium' | 'small',
};

type ListItemRenderProps = {
  item: Item,
  selected: boolean,
  onChange: OnChangeHandler,
  className?: string,
};

const getListItemRenderProps = (
  onChange: OnChangeHandler,
  selectedItem: Item,
  className?: string,
) => (item: Item) => ({
  item,
  onChange,
  selected: selectedItem?.value === item.value,
  className,
});

const renderListItem = ({
  item,
  selected,
  onChange,
  className,
}: ListItemRenderProps) => {
  const onClick = () => {
    !selected && onChange(item);
  };
  return (
    <ListItem
      selected={selected}
      onClick={onClick}
      key={item.value}
      className={`${className} ${item.value}`}
    >
      {item.label}
    </ListItem>
  );
};

const getListItems = (
  items: Item[],
  selectedItem: Item,
  onChange: OnChangeHandler,
  className?: string,
) => pipe(
  items,
  map(getListItemRenderProps(onChange, selectedItem, className)),
  map(renderListItem),
);

const Select = ({
  placeholder,
  items,
  value,
  onChange,
  onClose,
  className,
  type = 'medium',
}: SelectProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const closeDropdown = () => setIsOpen(false);
  const onClick = () => {
    setIsOpen(prev => !prev);
  };

  const listItems = getListItems(items, value, onChange, className);

  React.useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
  }, [isOpen, onClose]);

  useOnClickOutside(ref, closeDropdown);

  return (
    <Container role='button' onClick={onClick} ref={ref} small={type === 'small'}>
      <Label>
        {value.label || placeholder}
      </Label>
      <Arrow open={isOpen} />
      <ListContainer open={isOpen}>
        <List>
          {listItems}
        </List>
      </ListContainer>
    </Container>
  );
};

export default Select;
