import * as React from 'react';
import { navigate } from 'gatsby';
import { parse } from 'query-string';

import Select from '~/components/Select';

import {
  ContentsContainer, ContentsHider, ContentsSelectContainer, ContentsTitle,
} from './styles';

type Content = {
  content: string,
  title: string,
  id: number,
  version: string,
};

type Props = {
  currentCategory: string,
  contents: Content[],
  defaultContentVersion: string,
};

const getContentItem = (content: Content) => ({ label: content.version, value: content.version });

const Contents = ({
  currentCategory,
  contents,
  defaultContentVersion,
}: Props) => {
  const [currentVersion, setCurrentVersion] = React.useState('');
  const [currentContent, setCurrentContent] = React.useState('');
  const contentsItems = (contents || [])
    .map(getContentItem)
    .sort((a, b) => new Date(a.label).getTime() - new Date(b.label).getTime()).reverse();
  const querystringDate = typeof window !== 'undefined'
    ? parse(window.location.search).date as string
    : '';

  const navigateToDefaultVersion = React.useCallback(() => {
    const currentPathname = window.location.pathname;
    void navigate(`${currentPathname}?date=${defaultContentVersion}`, { replace: true });
    setCurrentVersion(defaultContentVersion);
  }, [defaultContentVersion]);

  React.useEffect(() => {
    if (!querystringDate && defaultContentVersion) {
      setCurrentVersion(defaultContentVersion);
      if (contents.length < 2) {
        return;
      }
      navigateToDefaultVersion();
      return;
    }
    if (!currentVersion) {
      setCurrentVersion(querystringDate);
    }
  }, [contents, defaultContentVersion, querystringDate, currentVersion, navigateToDefaultVersion]);

  React.useEffect(() => {
    if (currentVersion) {
      const currentVersionContent = contents
        .find((content) => content?.version === currentVersion)
        ?.content;
      if (typeof currentVersionContent === 'undefined') {
        navigateToDefaultVersion();
        return;
      }
      setCurrentContent(currentVersionContent);
    }
  }, [contents, currentVersion, defaultContentVersion, navigateToDefaultVersion]);

  const onChange = (item: {
    label: string,
    value: string,
  }) => {
    const selectedVersion = item.value;
    const currentPathname = window.location.pathname;
    void navigate(`${currentPathname}?date=${selectedVersion}`);
    setCurrentVersion(selectedVersion);
  };

  return (
    <>
      <ContentsTitle>{currentCategory}</ContentsTitle>
      {(currentVersion && contentsItems.length > 1) &&
          <ContentsSelectContainer>
            <Select
              className='contents-select-list-item'
              items={contentsItems}
              value={{ label: currentVersion, value: currentVersion }}
              onChange={onChange}
              type='small'
            />
          </ContentsSelectContainer>
      }
      <ContentsContainer dangerouslySetInnerHTML={{ __html: currentContent }} />
      <ContentsHider/>
    </>
  );
};

export default Contents;
