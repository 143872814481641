import * as React from 'react';

import {
  Container, CiContainer, SelectContainer,
} from './styles';

type Props = {
  logo: React.ReactNode,
  select: React.ReactNode,
};

const MobileHeader = ({ logo, select }: Props) => {
  return (
    <Container>
      <CiContainer>
        {logo}
      </CiContainer>
      <SelectContainer>
        {select}
      </SelectContainer>
    </Container>
  );
};

export default MobileHeader;
