import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import Header from '~/components/Header';

import { globalStyles, ContentContainer } from './styles';

type Props = {
  currentLanguage: string,
  languages: string[],
};

const Layout: React.FC<Props> = ({
  currentLanguage,
  languages,
  children,
}) => {
  globalStyles();
  return (
    <React.Fragment>
      <Helmet>
        <html lang={currentLanguage} />
      </Helmet>
      <ContentContainer>
        {children}
      </ContentContainer>
      <Header currentLanguage={currentLanguage} languages={languages} />
    </React.Fragment>
  );
};

export default Layout;
