import * as React from 'react';
import { navigate } from 'gatsby';

import Select from '~/components/Select';
import { ReactComponent as CiSvg } from '~/images/ci.svg';

import { Link } from './styles';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

type Props = {
  currentLanguage: string,
  languages: string[],
};

const LanguageMap = {
  'ko': '한국어',
  'en': 'English',
  'ja': '日本語',
  'zh-Hans': '簡體中文',
  'zh-Hant': '繁體中文',
  'th': 'ไทย',
  'ru': 'Русский',
  'pt': 'Português',
  'fr': 'Français',
  'es': 'Español',
  'de': 'Deutsch',
} as { [language: string]: string };

const getLanguageItem = (language: string) => ({
  value: language,
  label: LanguageMap[language] ?? `🚧 WARNING (${language}) 🚧`,
});

const Header = ({ currentLanguage, languages }: Props) => {
  const currentLanguageItem = getLanguageItem(currentLanguage);
  const languageItems = languages.map(getLanguageItem);

  const onChange = (item: typeof currentLanguageItem) => {
    void navigate(`/${item.value}/`);
  };

  const LogoComp = (
    <Link to={`/${currentLanguage}/`}>
      <CiSvg />
    </Link >
  );

  const SelectComp = (
    <Select
      className='language-select-list-item'
      value={currentLanguageItem}
      items={languageItems}
      onChange={onChange}
    />
  );

  return (
    <React.Fragment>
      <DesktopHeader logo={LogoComp} select={SelectComp} />
      <MobileHeader logo={LogoComp} select={SelectComp} />
    </React.Fragment>
  );
};

export default Header;
