import { rem } from 'polished';

import { styled } from '~/stitches.config';

export const Container = styled('nav', {
  marginTop: rem(30),
  ['@md']: {
    marginTop: rem(40),
  },
});

export const NavigationList = styled('ul', {
  dispaly: 'flex',
  overflowX: 'scroll',
  msOverflowStyle: 'none',
  overflow: '-moz-scrollbars-none',
  whiteSpace: 'nowrap',
  scrollBehavior: 'smooth',
  ['&::-webkit-scrollbar']: {
    display: 'none',
  },
  ['@lg']: {
    whiteSpace: 'initial',
    overflowX: 'hidden',
    flexWrap: 'wrap',
  },
});
