import { createStitches } from '@stitches/react';
import { em, rem } from 'polished';

export type MediaType = `@${'md' | 'lg'}`;
export const MediaTypeList: MediaType[] = [
  '@md',
  '@lg',
];
export type MediaTypeMap<T> = Partial<{ [i in MediaType]: T }>;

const mapPropertyByType = (value: string | number) => typeof value === 'string' ? value : rem(value);

const mobileHeaderHeight = rem(110);
const desktopHeaderHeight = rem(60);

export const { styled, css, globalCss, getCssText, createTheme } = createStitches({
  media: {
    md: `(min-width: ${em(640)})`,
    lg: `(min-width: ${em(1200)})`,
  },
  theme: {
    colors: {
      orange: '#ff5f00',
      border: '#E4E4E6',
      white: '#fff',
      black: '#000',
      text: '#70737C',
      textPrimary: '#24272D',
      backgroundIcon: '#3F444E',
      backgroundOverlay: 'rgba(112, 115, 124, 0.1)',
    },
    space: {
      mobileHeaderHeight,
      desktopHeaderHeight,
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
    sizes: {
      maxContents: rem(1100),
      mobileHeaderHeight,
      desktopHeaderHeight,
    },
    shadows: {
      borderBottomGray: `0 ${rem(1)} ${rem(1)} #E4E4E6`,
      borderGray: `0 0 0 ${rem(1)} #E4E4E6`,
    },
    transitions: {
    },
  },
  utils: {
    /**
     * margin
     */
    marginX: (value: number | string) => ({
      marginLeft: mapPropertyByType(value),
      marginRight: mapPropertyByType(value),
    }),
    marginY: (value: number | string) => ({
      marginTop: mapPropertyByType(value),
      marginBottom: mapPropertyByType(value),
    }),

    /**
     * padding
     */
    paddingX: (value: number | string) => ({
      paddingLeft: mapPropertyByType(value),
      paddingRight: mapPropertyByType(value),
    }),
    paddingY: (value: number | string) => ({
      paddingTop: mapPropertyByType(value),
      paddingBottom: mapPropertyByType(value),
    }),

    /**
     * Flex.tsx
     */
    row: () => ({
      flexDirection: 'row',
    }),
    column: () => ({
      flexDirection: 'column',
    }),
    center: () => ({
      justifyContent: 'center',
      alignItems: 'center',
    }),
    rowCenterX: () => ({
      flexDirection: 'row',
      justifyContent: 'center',
    }),
    rowCenterY: () => ({
      flexDirection: 'row',
      alignItems: 'center',
    }),
    colCenterX: () => ({
      flexDirection: 'column',
      alignItems: 'center',
    }),
    colCenterY: () => ({
      flexDirection: 'column',
      justifyContent: 'center',
    }),
  },
});
