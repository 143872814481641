import * as React from 'react';

import {
  CiContainer,
  Container, Contents, SelectContainer,
} from './styles';

type Props = {
  logo: React.ReactNode,
  select: React.ReactNode,
};

const Header = ({ logo, select }: Props) => {
  return (
    <Container>
      <Contents>
        <CiContainer>
          {logo}
        </CiContainer>
        <SelectContainer>
          {select}
        </SelectContainer>
      </Contents>
    </Container>
  );
};

export default Header;
