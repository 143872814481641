import { styled } from '~/stitches.config';

export const Container = styled('header', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '$mobileHeaderHeight',
  backgroundColor: '$white',
  ['@md']: {
    display: 'none',
  },
});

const ContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  boxShadow: '$borderBottomGray',
});

export const CiContainer = styled(ContentContainer, {
  height: '$desktopHeaderHeight',
  paddingX: 16,
});

export const SelectContainer = styled(ContentContainer, {
  height: 'calc(100% - $desktopHeaderHeight)',
});
