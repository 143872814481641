import * as React from 'react';

import NavigationListItem from './NavigationListItem';
import { Container, NavigationList } from './styles';

type Item = { id: number, name: string, urlSlug: string };
type Props = {
  currentLanguage: string,
  categories: Item[],
  currentCategoryId: number,
};

const CategoryNavigation = ({
  currentLanguage,
  categories,
  currentCategoryId,
}: Props) => {
  const containerRef = React.useRef<HTMLUListElement>(null);

  return (
    <Container>
      <NavigationList ref={containerRef}>
        {categories.map((category) => (
          <NavigationListItem
            key={category.id}
            selected={category.id === currentCategoryId}
            to={`/${currentLanguage}/${category.urlSlug}/`}
            name={category.name}
            containerRef={containerRef}
          />
        ))}
      </NavigationList>
    </Container>
  );
};

export default CategoryNavigation;
